.project-container{
  padding-top: 50px;
  background-color: white;
  z-index: 0;
}

.project-header-container{
  padding-bottom: 50px;
}
.project-header {
  font-size: 40px;
  font-family: 'Roboto', sans-serif;

  display: flex;
  justify-content: center;
  font-weight: bold;
}