section {
  z-index: -1;
  position: relative;
  width: 100%;
  height: 100vh;
  background: #aa00ff;
  overflow: hidden;
}

section .wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url('https://i.imgur.com/j158eBf.png');
  background-size: 1000px 100px
}

section .wave.wave1 {
  animation: animate 30s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}

@keyframes animate
{
  0%{
    background-position-x: 0;
  }

  100%{
    background-position-x: 1000px;
  }
}

section .wave.wave2 {
  animation: animate2 30s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}

@keyframes animate2
{
  0%{
    background-position-x: 0;
  }

  100%{
    background-position-x: -1000px;
  }
}

.title {
  font-weight: bold;
  font-size: 50px !important;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 10px;
}

.subtitle {
  font-size: 25px !important;
  font-family: 'Roboto', sans-serif;
}

.title, .subtitle {
  text-align: center;
  color: whitesmoke
}

.home_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title_text_container {
  width: 70%;
  padding: 2%;
  border: 4px white groove;
  margin-bottom: 30%
}