.navbar {
  position: sticky;
  top: 0;
  z-index: 1;

  height: 25px;
  width: 100%;
  border-bottom: 20px;

  background-color: #CCCCCC;
  border-bottom: 4px solid #339900;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  padding: 2%;

}

.link {
  position: relative;

}