.about_container {
  padding-top: 50px;
  background-color: #f0f0f0;
}

.about_header {
  font-size: 40px;
  font-family: 'Roboto', sans-serif;

  display: flex;
  justify-content: center;
  font-weight: bold;
}

.about_paragraph_container{
  display: block;
  margin: auto;
  width: 60%;
}

.about_paragraph {
  padding-top: 10px;
  font-family: 'Roboto', sans-serif;
}

.selfie {
  display: block;
  margin: auto;
  width: 60%;
}

.icon_container {
  display: flex;
  justify-content: center;
}

.icon_grid {
  display: grid;
  grid-template-columns: 60px 60px;

  justify-items: center;

}

.icons {
  font-size: 50px;
}