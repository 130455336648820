.contact-container {
  padding-top: 50px;
  background-color: white;
  z-index: 0;
}

.contact-header {
  font-size: 40px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.contact-form-container {
  margin: auto;
  width: 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

}